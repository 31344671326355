<template>
  <v-footer
    app
    class="justify-center"
    padless
    absolute
  >
    <v-container fluid>
      <v-row
        dense
        justify="center"
        align="center"
      >
        <v-col xl="2">
          <v-card-text class="black--text text-center">
            <a
              :href="pdfLink"
              download="download"
            >Download Legal Mentions (pdf)</a>
          </v-card-text>
          <v-card-text class="black--text text-center">
            {{ new Date().getFullYear() }} — <strong>Noell International</strong>
          </v-card-text>
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
  import {
    sync,
  } from 'vuex-pathify'

  export default {
    name: 'FrontendFooter',
    data: () => ({
      pdfLink: require('../../../assets/mentions_legales.pdf'),
      date: (new Date()).getFullYear(),
      address: 'BP 5674<br/>56370 SARZEAU<br/>France',
      icon_email: '$mdiEmailSend',
      icon_linkedin: '$mdiLinkedin',
      icon_phone: '$mdiPhoneForward',
      linkedin_link: 'https://www.linkedin.com/in/laurentnoell/',
    }),

    computed: {
      ...sync('frontend/*'),
    },
  }
</script>
